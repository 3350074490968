import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../../styles/global.scss"

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import 'bootstrap/dist/css/bootstrap.min.css';
// import MenuList from "../components/MenuList"

const IndexPage = () => (
  <Layout title="HWMR - Morning Nourishment">
    <SEO title="The Holy Word for Morning Revival"/>
    <section>
    <div className="container">
    <h2>Semiannual Training June 29 – July 4, 2020</h2>
    <h3>Crystallization-study of Jeremiah and Lamentations</h3> 
    {/*  Start of the Accordion boxes for the weekly verses. 6 weeks a time. 
         Also defaultActiveKey="#" defines which card is automatically opened. */}
    <Accordion defaultActiveKey="6">
      {/*  Daily verses for Week 1  */}
      <Card>
         <Accordion.Toggle as={Card.Header} eventKey="1">
            Week 1: Jeremiah, the Tenderhearted Prophet of the Tenderhearted God
         </Accordion.Toggle>
         <Accordion.Collapse eventKey="1">
            <Card.Body>
               <ul>
               <li><strong>Day 1</strong></li>
                  <li><strong>Jeremiah 1:18-19</strong>  <br></br><strong><sup>[18]</sup></strong>And I am now making you today into a fortified city and into an iron pillar and into bronze walls against the whole land, against the kings of Judah, against its princes, against its priests, and against the people of the land.  <br></br><strong><sup>[19]</sup></strong>And they will fight against you, but they will not prevail against you; for I am with you, declares Jehovah, to deliver you.</li>
                  <br></br>
               <li><strong>Day 2</strong></li>
                  <li><strong>Jeremiah 1:9-10</strong> <br></br><strong><sup>[9]</sup></strong> Then Jehovah stretched out His hand and touched my mouth; and Jehovah said to me, Now I have put My words in your mouth. <br></br><strong><sup>[10]</sup></strong> See, I have appointed you this day / Over the nations and over the kingdoms / To pluck up and to break down, / To destroy and to tear down, / To build up and to plant.</li>
                  <br></br>
               <li><strong>Day 3</strong></li>
               <li><strong>Jeremiah 9:17-19</strong> <br></br><strong><sup>[17]</sup></strong> Thus says Jehovah of hosts, / Consider, and call for the mourning women to come, / And send for the skillful women to come; <br></br><strong><sup>[18]</sup></strong>Let them hasten and take up a wailing for us, / That our eyes may shed tears / And our eyelids may pour forth water. <br></br><strong><sup>[19]</sup></strong>For a voice of wailing / Was heard from Zion: / How we are ruined! / We are utterly put to shame! / For we have forsaken the land / Because they have cast down our dwellings.</li>
                  <br></br>
               <li><strong>Day 4</strong></li>
                  <li><strong>Jeremiah 9:1</strong>  Oh that my head were waters, / And my eye a fountain of tears, / That I might weep day and night / For the slain of the daughter of my people!</li>
                  <li><strong>Acts 20:19 </strong>  Serving the Lord as a slave with all humility and tears and trials which came upon me by the plots of the Jews;</li> 
                  <br></br>
               <li><strong>Day 5</strong></li>
                  <li><strong>2 Corinthians 12:15</strong> But I, I will most gladly spend and be utterly spent on behalf of your souls. If I love you more abundantly, am I loved less?</li>
                  <li><strong>2 Corinthians 7:3</strong> I do not say this to condemn you, for I have said before that you are in our hearts for our dying together and our living together.</li>
                  <br></br>
               <li><strong>Day 6</strong></li>
                  <li><strong>2 Corinthians 11:28</strong>  Apart from the things which have not been mentioned, there is this: the crowd of cares pressing upon me daily, the anxious concern for all the churches.</li>
                  <li><strong>Psalms 84:5-6</strong>  <br></br><strong><sup>[5]</sup></strong>Blessed is the man whose strength is in You, / In whose heart are the highways to Zion. <br></br> <strong><sup>[6]</sup></strong>Passing through the valley of Baca, / They make it a spring; / Indeed the early rain covers it with blessings.</li>
               </ul>  <strong><sup>[9]</sup></strong> 
            </Card.Body>
         </Accordion.Collapse>
      </Card>
     {/* Daily verses for Week 2 */}
      <Card>
         <Accordion.Toggle as={Card.Header} eventKey="2">
            Week 2: The Kernel of the Book of Jeremiah
         </Accordion.Toggle>
         <Accordion.Collapse eventKey="2">
            <Card.Body>
            <ul>
               <li><strong>Day 1</strong></li>
                  <li><strong>Jeremiah 2:13</strong>  For My people have committed two evils: / They have forsaken Me, / The fountain of living waters, / To hew out for themselves cisterns, / Broken cisterns, / Which hold no water.</li>
                  <li><strong>Isaiah 12:3-6</strong> <br></br><strong><sup>[3]</sup></strong>Therefore you will draw water with rejoicing / From the springs of salvation, <br></br><strong><sup>[4] </sup></strong>And you will say in that day, / Give thanks to Jehovah; call upon His name! / Make His deeds known among the peoples; / Remind [them] that His name is exalted.<br></br> <strong><sup>[5]</sup></strong>Sing psalms to Jehovah, for He has done something majestic! / Let it be made known in all the earth!<br></br> <strong><sup>[6]</sup></strong>Cry out and give a ringing shout, O inhabitant of Zion, / For great in your midst is the Holy One of Israel.</li>
                  <br></br>
               <li><strong>Day 2</strong></li>
                  <li><strong>John 4:10</strong> Jesus answered and said to her, If you knew the gift of God and who it is who says to you, Give Me a drink, you would have asked Him, and He would have given you living water.</li>
                  <li><strong>John 4:14</strong> But whoever drinks of the water that I will give him shall by no means thirst forever; but the water that I will give him will become in him a fountain of water springing up into eternal life.</li>
                  <br></br>
               <li><strong>Day 3</strong></li>
               <li><strong>Jeremiah 2:13</strong>  For My people have committed two evils: / They have forsaken Me, / The fountain of living waters, / To hew out for themselves cisterns, / Broken cisterns, / Which hold no water.</li>
               <li><strong>Jeremiah 13:23</strong> Can the Cushite change his skin, / Or the leopard his spots? / [Then] you also may be able to do good, / Who are accustomed to do evil.</li>
                  <br></br>
               <li><strong>Day 4</strong></li>
                  <li><strong>Isaiah 6:5</strong>  Then I said, Woe is me, for I am finished! / For I am a man of unclean lips, / And in the midst of a people of unclean lips I dwell; / Yet my eyes have seen the King, Jehovah of hosts.</li>
                  <li><strong>1 John 1:7</strong>  But if we walk in the light as He is in the light, we have fellowship with one another, and the blood of Jesus His Son cleanses us from every sin.</li> 
                  <br></br>
               <li><strong>Day 5</strong></li>
                  <li><strong>Jeremiah 23:5-6</strong><br></br>  <strong><sup>[5]</sup></strong>Indeed, days are coming, / Declares Jehovah, / When I will raise up to David a righteous Shoot; / And He will reign as King and act prudently / And will execute justice and righteousness in the land. <br></br><strong><sup>[6]</sup></strong>In His days Judah will be saved, / And Israel will dwell securely; / And this is His name by which He will be called, / Jehovah our righteousness.</li>
                  <li><strong>Jeremiah 33:16</strong> In those days Judah will be saved, and Jerusalem will dwell securely; and this is [the name] by which she will be called: Jehovah our righteousness.</li>
                  <br></br>
               <li><strong>Day 6</strong></li>
                  <li><strong>Jeremiah 31:31</strong>  Indeed, days are coming, declares Jehovah, when I will make a new covenant with the house of Israel and with the house of Judah,</li>
                  <li><strong>Jeremiah 31:33-34</strong>  <br></br><strong><sup>[33]</sup></strong>But this is the covenant which I will make with the house of Israel after those days, declares Jehovah: I will put My law in their inward parts and write it upon their hearts; and I will be their God, and they will be My people. <br></br> <strong><sup>[34]</sup></strong>Passing through the valley of Baca, / They make it a spring; / Indeed the early rain covers it with blessings.</li>
               </ul>
            </Card.Body>
         </Accordion.Collapse>
      </Card>
      {/* Daily verses for Week 3 */}
      <Card>
         <Accordion.Toggle as={Card.Header} eventKey="3">
            Week 3: The Two Evils of God’s People and God’s Faithfulness in Fulfilling His Economy
         </Accordion.Toggle>
         <Accordion.Collapse eventKey="3">   
            <Card.Body>
            <ul>
               <li><strong>Day 1</strong></li>
                  <li><strong>Jeremiah 2:13</strong>  For My people have committed two evils: / They have forsaken Me, / The fountain of living waters, / To hew out for themselves cisterns, / Broken cisterns, / Which hold no water.</li>
                  <li><strong>Exodus 17:6</strong> I will be standing before you there upon the rock in Horeb; and you shall strike the rock, and water will come out of it so that the people may drink. And Moses did so in the sight of the elders of Israel.</li>
                  <br></br>
               <li><strong>Day 2</strong></li>
                  <li><strong>Revelation 22:1</strong> Jesus answered and said to her, If you knew the gift of God and who it is who says to you, Give Me a drink, you would have asked Him, and He would have given you living water.</li>
                  <li><strong>Revelation 22:17</strong> I will be standing before you there upon the rock in Horeb; and you shall strike the rock, and water will come out of it so that the people may drink. And Moses did so in the sight of the elders of Israel.</li>
                  <br></br>
               <li><strong>Day 3</strong></li>
               <li><strong>Jeremiah 17:9</strong> And he showed me a river of water of life, bright as crystal, proceeding out of the throne of God and of the Lamb in the middle of its street.</li>
               <li><strong>Jeremiah 13:23</strong> And the Spirit and the bride say, Come! And let him who hears say, Come! And let him who is thirsty come; let him who wills take the water of life freely.</li>
                  <br></br>
               <li><strong>Day 4</strong></li>
                  <li><strong>Isaiah 557</strong>  Let the wicked forsake his way, / And the evildoer, his thoughts; / And let him return to Jehovah, and He will have compassion on him; / And to our God, for He will pardon abundantly.</li>
                  <li><strong>Ezekiel 14:3</strong>  Son of man, these men have set up their idols in their hearts and have put the stumbling block of their iniquity before their faces. Should I be inquired of at all by them?   </li>
                  <li><strong>Ezekiel 14:5</strong>  That I may lay hold of the house of Israel in their heart, who have become estranged from Me because of all their idols.</li>  
                  <br></br>
               <li><strong>Day 5</strong></li>
                  <li><strong>Lamentations 3:22-23</strong> <br></br><strong><sup>[22]</sup></strong>It is Jehovah’s lovingkindness that we are not consumed, / For His compassions do not fail; <br></br><strong><sup>[23]</sup></strong>They are new every morning; / Great is Your faithfulness.</li>
                  <li><strong>1 Corinthians 1:9</strong> God is faithful, through whom you were called into the fellowship of His Son, Jesus Christ our Lord.</li>
                  <br></br>
               <li><strong>Day 6</strong></li>
                  <li><strong>Lamentations 3:22-23</strong> <br></br><strong><sup>[22]</sup></strong>It is Jehovah’s lovingkindness that we are not consumed, / For His compassions do not fail; <br></br><strong><sup>[23]</sup></strong>They are new every morning; / Great is Your faithfulness. <br></br><strong><sup>[24]</sup></strong>Jehovah is my portion, says my soul; / Therefore I hope in Him. <br></br><strong><sup>[25]</sup></strong>Jehovah is good to those who wait on Him, / To the soul that seeks Him.</li>
               </ul>
            </Card.Body>
         </Accordion.Collapse>
      </Card>
      {/* Daily verses for Week 4 */}
      <Card>
      <Accordion.Toggle as={Card.Header}  eventKey="4">
            Week 4: God’s Words—the Divine Supply as Food
         </Accordion.Toggle>
         <Accordion.Collapse eventKey="4">
            <Card.Body>
            <ul>
               <li><strong>Day 1</strong></li>
                  <li><strong>Matthew 4:4</strong>  For My people have committed two evils: / They have forsaken Me, / The fountain of living waters, / To hew out for themselves cisterns, / Broken cisterns, / Which hold no water.</li>
                  <li><strong>John 6:63</strong> It is the Spirit who gives life; the flesh profits nothing; the words which I have spoken to you are spirit and are life.</li>
                  <br></br>
               <li><strong>Day 2</strong></li>
                  <li><strong>Jeremiah 15:16</strong> Your words were found and I ate them, and Your word became to me the gladness and joy of my heart, for I am called by Your name, O Jehovah, God of hosts.</li>
                  <li><strong>John 6:57-58</strong> <br></br><strong><sup>[57]</sup></strong>As the living Father has sent Me and I live because of the Father, so he who eats Me, he also shall live because of Me. <br></br><strong><sup>[58]</sup></strong>This is the bread which came down out of heaven, not as the fathers ate and died; he who eats this bread shall live forever.</li>
                  <br></br>
               <li><strong>Day 3</strong></li>
               <li><strong>John 6:48-51</strong> <br></br><strong><sup>[48]</sup></strong>I am the bread of life. <br></br><strong><sup>[49]</sup></strong>Your fathers ate the manna in the wilderness, and they died. <br></br><strong><sup>[50]</sup></strong>This is the bread which comes down out of heaven, that anyone may eat of it and not die. <br></br><strong><sup>[51]</sup></strong>I am the living bread which came down out of heaven; if anyone eats of this bread, he shall live forever; and the bread which I will give is My flesh, given for the life of the world.</li>
                  <br></br>
               <li><strong>Day 4</strong></li>
                  <li><strong>Jeremiah 15:16</strong> Your words were found and I ate them, and Your word became to me the gladness and joy of my heart, for I am called by Your name, O Jehovah, God of hosts.</li>
                  <li><strong>John 15:11</strong>  These things I have spoken to you that My joy may be in you and...your joy may be made full.</li>
                  <li><strong>1 Peter 1:8</strong> Whom having not seen, you love; into whom though not seeing Him at present, yet believing, you exult with joy that is unspeakable and full of glory.</li> 
                  <br></br>
               <li><strong>Day 5</strong></li>
                  <li><strong>Colossians 3:16</strong> Let the word of Christ dwell in you richly in all wisdom, teaching and admonishing one another with psalms and hymns and spiritual songs, singing with grace in your hearts to God.</li>
                  <li><strong>John 6:63</strong> It is the Spirit who gives life; the flesh profits nothing; the words which I have spoken to you are spirit and are life.</li>
                  <br></br>
               <li><strong>Day 6</strong></li>
                  <li><strong>Ephesians 3:16-17</strong>  <br></br><strong><sup>[16]</sup></strong>That He would grant you, according to the riches of His glory, to be strengthened with power through His Spirit into the inner man, <br></br><strong><sup>[17]</sup></strong>That Christ may make His home in your hearts through faith, that you, being rooted and grounded in love,</li>
                  <li><strong>Ephesians 6:17-18</strong>  <br></br><strong><sup>[17]</sup></strong>And receive the helmet of salvation and the sword of the Spirit, which Spirit is the word of God, <br></br> <strong><sup>[18]</sup></strong>By means of all prayer and petition, praying at every time in spirit and watching unto this in all perseverance and petition concerning all the saints,</li>
               </ul>
            </Card.Body>
         </Accordion.Collapse>
      </Card>
      {/* Daily verses for Week 5 */}
      <Card>
      <Accordion.Toggle as={Card.Header} eventKey="5"> 
         Week 5: God as Our Sovereign Potter Making Us His Vessels, His Containers, to Contain Him
         </Accordion.Toggle>
         <Accordion.Collapse eventKey="5">
            <Card.Body>
            <ul>
               <li><strong>Day 1</strong></li>
                  <li><strong>Jeremiah 18:6</strong>  Am I not able to do with you, O house of Israel, as this potter does? declares Jehovah. Indeed, as the clay is in the hand of the potter, so you are in My hand, O house of Israel.</li>
                  <li><strong>Isaiah 64:8</strong> But now, Jehovah, You are our Father; we are the clay; and You, our Potter; and all of us are the work of Your hand.</li>
                  <br></br>
               <li><strong>Day 2</strong></li>
                  <li><strong>Acts 9:15</strong> But the Lord said to him, Go, for this man is a chosen vessel to Me, to bear My name before both the Gentiles and kings and the sons of Israel;</li>
                  <li><strong>Ephesians 3:19</strong> And to know the knowledge-surpassing love of Christ, that you may be filled unto all the fullness of God.</li>
                  <li><strong>Luke 1:53</strong> The hungry He has filled with good things, and the rich He has sent away empty.</li>
                  <br></br>
               <li><strong>Day 3</strong></li>
                  <li><strong>Ephesians 2:1</strong> And you, though dead in your offenses and sins.</li>
                  <li><strong>Ephesians 2:4</strong> But God, being rich in mercy, because of His great love with which He loved us.</li>
                  <li><strong>Hebrews 4:16</strong> Let us therefore come forward with boldness to the throne of grace that we may receive mercy and find grace for timely help.</li>
                  <br></br>
               <li><strong>Day 4</strong></li>
                  <li><strong>Romans 9:15-16</strong> <br></br><strong><sup>[15]</sup></strong>For to Moses He says, “I will have mercy on whomever I will have mercy, and I will have compassion on whomever I will have compassion.” <br></br><strong><sup>[16]</sup></strong>So then it is not of him who wills, nor of him who runs, but of God who shows mercy.</li>
                  <li><strong>Matthew 9:13</strong> But go and learn what this means, “I desire mercy and not sacrifice,” for I did not come to call the righteous, but sinners.</li> 
                  <br></br>
               <li><strong>Day 5</strong></li>
                  <li><strong>Romans 9:16</strong> So then it is not of him who wills, nor of him who runs, but of God who shows mercy.</li>
                  <li><strong>Romans 9:23</strong> In order that He might make known the riches of His glory upon vessels of mercy, which He had before prepared unto glory.</li> 
                  <li><strong>Luke 1:78</strong> Because of the merciful compassions of our God, in which the rising sun will visit us from on high.</li>
                  <br></br>
               <li><strong>Day 6</strong></li>
                  <li><strong>2 Corinthians 3:16</strong> But whenever their heart turns to the Lord, the veil is taken away.</li>
                  <li><strong>2 Corinthians 3:18</strong> But we all with unveiled face, beholding and reflecting like a mirror the glory of the Lord, are being transformed into the same image from glory to glory, even as from the Lord Spirit.</li>
               
               </ul>
            </Card.Body>
         </Accordion.Collapse>
      </Card>
      {/* Daily verses for Week 6 */}
      <Card>
      <Accordion.Toggle as={Card.Header} div className="card text-white bg-info" eventKey="6">
      <span style={{fontWeight: `bold`}}>Week 6: The Principle of Being One with God as Revealed in the Book of Jeremiah</span>
         </Accordion.Toggle>
         <Accordion.Collapse eventKey="6">
            <Card.Body>
            <ul>
               <li><strong>Day 1</strong></li>
                  <li><strong>Genesis 1:26</strong> And God said, Let Us make man in Our image, according to Our likeness; and let them have dominion over the fish of the sea and over the birds of heaven and over the cattle and over all the earth and over every creeping thing that creeps upon the earth.</li>
                  <li><strong>Acts 7:56</strong> And he said, Behold, I see the heavens opened up and the Son of Man standing at the right hand of God.</li>
                  <br></br>
               <li><strong>Day 2</strong></li>
                  <li><strong>1 John 3:2</strong> Beloved, now we are children of God, and it has not yet been manifested what we will be. We know that if He is manifested, we will be like Him because we will see Him even as He is.</li>
                  <li><strong>Revelation 4:3</strong> And He who was sitting was like a jasper stone and a sardius in appearance, and there was a rainbow around the throne like an emerald in appearance.</li>
                  <li><strong>Revelation 21:11</strong> Having the glory of God. Her light was like a most precious stone, like a jasper stone, as clear as crystal.</li>
                  <br></br>
               <li><strong>Day 3</strong></li>
                  <li><strong>Jeremiah 2:13</strong> For My people have committed two evils: / They have forsaken Me, / The fountain of living waters, / To hew out for themselves cisterns, / Broken cisterns, / Which hold no water.</li>
                  <li><strong>Genesis 2:8-9</strong> <br></br><strong><sup>[8]</sup></strong>And Jehovah God planted a garden in Eden, in the east; and there He put the man whom He had formed. <br></br><strong><sup>[9]</sup></strong>And out of the ground Jehovah God caused to grow every tree that is pleasant to the sight and good for food, as well as the tree of life in the middle of the garden and the tree of the knowledge of good and evil. </li>
                  <br></br>
               <li><strong>Day 4</strong></li>
                  <li><strong>Jeremiah 15:19</strong> Therefore thus says Jehovah, / If you return, I will restore you; / You will stand before Me; / And if you bring out the precious from the worthless, / You will be as My mouth; / They will turn to you, / But you will not turn to them.</li>
                  <li><strong>Philippians 3:8</strong>  But moreover I also count all things to be loss on account of the excellency of the knowledge of Christ Jesus my Lord, on account of whom I have suffered the loss of all things and count them as refuse that I may gain Christ</li> 
                  <br></br>
               <li><strong>Day 5</strong></li>
                  <li><strong>Romans 8:4</strong> But moreover I also count all things to be loss on account of the excellency of the knowledge of Christ Jesus my Lord, on account of whom I have suffered the loss of all things and count them as refuse that I may gain Christ</li>
                  <li><strong>2 Corinthians 2:10</strong> But whom you forgive anything, I also forgive; for also what I have forgiven, if I have forgiven anything, it is for your sake in the person of Christ;</li>
                  <br></br>
               <li><strong>Day 6</strong></li>
                  <li><strong>Jeremiah 23:5-6</strong>  <br></br><strong><sup>[5]</sup></strong>Indeed, days are coming, / Declares Jehovah, / When I will raise up to David a righteous Shoot; / And He will reign as King and act prudently / And will execute justice and righteousness in the land. <br></br><strong><sup>[6]</sup></strong>In His days Judah will be saved, And Israel will dwell securely; / And this is His name by which He will be called: / Jehovah our righteousness.</li>
                  <li><strong>Jeremiah 31:33</strong>  But this is the covenant which I will make with the house of Israel after those days, declares Jehovah: I will put My law in their inward parts and write it upon their hearts; and I will be their God, and they will be My people.</li>
               </ul>
            </Card.Body>
         </Accordion.Collapse>
      </Card>
      </Accordion>
     <h2 className="title"></h2><br></br>
      <br></br>
      <p><strong><u>Resources:</u></strong></p> 
      <ul>
         {/* Message */}
         <li>Crystallization-study <a href="http://www.churchinwinnipeg.org/wp-content/uploads/2020/06/TS20TRN_en.pdf" target="_blank" rel="noopener noreferrer">Outline</a></li>
         <li>All verses are quoted from the <a href="https://www.recoveryversion.bible/" target="_blank" rel="noopener noreferrer">Holy Bible Recovery Version</a>.</li>
         <li>You can order a free copy of the New Testament Recovery Version <a href="http://biblesforamerica.org/free-bible/" target="_blank" rel="noopener noreferrer">here.</a></li>
      </ul>
    </div>
    </section>
  </Layout>
)

export default IndexPage
